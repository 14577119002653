import styled from 'styled-components';

import * as text from '@/components/general/TextStyles';

export const Title = styled.h2`
  ${text.FS24toFS20}
  margin-top: 22px;
  margin-bottom: -8px;
  position: relative;
`;

export const Anchor = styled.div`
  position: absolute;
  left: 0;
  top: -${(props) => props.theme.headerSize.heightDesktop + 9}px;

  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    top: -${(props) => props.theme.headerSize.heightMobile + 9}px;
  }
`;

export const Text = styled.p`
  ${text.FS17}
`;

export const ImageWrapper = styled.div`
  margin-top: 18px;
  margin-bottom: 2px;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}px) {
    margin: 0;
  }
`;

export const UlList = styled.ul`
  ${text.FS18}
  line-height: 170%;
  margin: 15px 0 15px 17px;

  & li {
    list-style: disc;
  }
`;

export const OlList = styled.ol`
  ${text.FS18}
  line-height: 170%;
  margin: 15px 0 15px 27px;
`;

export const ListItem = styled.li``;

export const CustomList = styled.ul`
  margin: 10px 0;
`;

export const CustomListItem = styled.li`
  display: flex;
  align-items: center;
  list-style: none;
  &:not(:last-child) {
    margin-bottom: 18px;

    @media (max-width: ${(props) => props.theme.breakpoints.sm}px) {
      margin-bottom: 15px;
    }
  }
`;

export const CustomListMarker = styled.img`
  margin-right: 15px;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}px) {
    width: 20px;
    height: 20px;
  }
`;

export const CustomListItemText = styled.span`
  ${text.FS17}
  font-weight: ${(props) => props.theme.weights.bold};
`;

export const Link = styled.a`
  transition: border-bottom 0.2s ease;
  border-bottom: 2px solid ${(props) => props.theme.colors.blue};

  &:hover {
    border-bottom: 2px solid ${(props) => props.theme.colors.black};
  }
`;
