import React from 'react';

import { PRODUCTS } from '@/components/Products/ProductsInfo';
import * as styled from './WhatSection.styled';

import { GridItem } from '../GridItem';

export const WhatSection: React.FC = () => (
  <styled.WhatSection id="all-products">
    <styled.WhatTitle>What is CodePlatform?</styled.WhatTitle>
    <styled.WhatGrid>
      {PRODUCTS.map((item) => (
        <GridItem key={item.title} item={item} />
      ))}
    </styled.WhatGrid>
  </styled.WhatSection>
);
