import React from 'react';

import { Tag, TagAttachment } from '@/components/Templates/components';
import * as styled from './Header.styled';

interface IHeader {
  title: string;
  date: Date;
  tags: string[];
  description: string;
  image: any;
  author: any;
}

export const Header: React.FC<IHeader> = ({
  title,
  date,
  tags,
  description,
  image,
  author,
}) => {
  const { name, jobTitle, authorLinks, photo } = author;
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const articleDate = new Date(date);

  const month = monthNames[articleDate.getMonth()];
  const day = articleDate.getDate();
  const year = articleDate.getFullYear();

  return (
    <styled.Header>
      <styled.LgDesription itemProp="description">
        {description}
      </styled.LgDesription>
      <styled.TopPicture src={image.url} itemProp="image" />
      <styled.HeaderDescriptionBox>
        <styled.Date itemProp="datePublished">{`${month.toUpperCase()} ${day}, ${year}`}</styled.Date>
        <styled.Title itemProp="headline">{title}</styled.Title>
        <styled.TagsBox>
          {tags.map((tag) => (
            <Tag key={tag} attachment={TagAttachment.BLOG} itemProp="keywords">
              {tag}
            </Tag>
          ))}
        </styled.TagsBox>
        <styled.Desription itemProp="description">
          {description}
        </styled.Desription>
        <styled.ProfileInHeader>
          <styled.ProfileHeadInHeader>
            <styled.ProfilePhoto src={photo?.url} />
            <styled.ProfileDescription>
              <styled.Name>{name}</styled.Name>
              <styled.JobTitle>{jobTitle}</styled.JobTitle>
              <styled.AuthorLinksBox>
                {authorLinks?.map((url) => (
                  <styled.AuthorLink key={url.link} href={url.link}>
                    {url.text}
                  </styled.AuthorLink>
                ))}
              </styled.AuthorLinksBox>
            </styled.ProfileDescription>
          </styled.ProfileHeadInHeader>
        </styled.ProfileInHeader>
      </styled.HeaderDescriptionBox>
    </styled.Header>
  );
};
