import styled from 'styled-components';
import * as text from '@/components/general/TextStyles';

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  column-gap: 60px;
  max-width: 1240px;
  margin: 144px auto 107px;
  pointer-events: all;

  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    flex-direction: column-reverse;
    margin-top: 50px;
    margin-bottom: 30px;
    align-items: flex-start;
  }
`;

export const TopPicture = styled.img`
  width: 430px;
  border-radius: 24px;
  aspect-ratio: 1/0.814;
  object-fit: cover;
  object-position: top;

  @media (max-width: ${(props) => props.theme.breakpoints.xl}px) {
    width: 430px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    width: 334px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.sm}px) {
    width: 100%;
    align-self: center;
  }
`;

export const HeaderDescriptionBox = styled.div`
  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    margin-bottom: 30px;
  }
`;

export const Date = styled.span`
  ${text.FS14}
  color: ${(props) => props.theme.colors.grayText};
`;

export const Title = styled.h1`
  margin-top: 10px;
  margin-bottom: 15px;
  ${text.FS42_LG}

  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    margin-top: 15px;
    margin-bottom: 15px;
  }
`;

export const TagsBox = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}px) {
    flex-wrap: wrap;
    row-gap: 10px;
  }
`;

export const Desription = styled.p`
  ${text.FS22}
  margin-top: 20px;

  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    display: none;
  }
`;

export const LgDesription = styled(Desription)`
  display: none;

  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    display: block;
    font-size: 18px;
  }
`;

export const ProfileInHeader = styled.div`
  display: none;

  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    margin-top: 30px;
    display: block;
  }
`;

export const ProfileHeadInHeader = styled.div`
  display: flex;
  border: none;
  padding: 0;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}px) {
    justify-content: start;
  }
`;

export const ProfilePhoto = styled.img`
  width: 70px;
  height: 70px;
  border-radius: 50%;
`;

export const ProfileDescription = styled.div`
  margin-left: 14px;
`;

export const Name = styled.span`
  ${text.FS14}
  font-weight: ${(props) => props.theme.weights.bold};
  margin-bottom: 3px;
`;

export const JobTitle = styled.p`
  ${text.FS14}
  line-height: 150%;
  margin-bottom: 6px;
`;

export const AuthorLinksBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 15px;
  row-gap: 10px;
`;

export const AuthorLink = styled.a`
  ${text.FS14}
  color: ${(props) => props.theme.colors.blue}!important;
`;
