/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { removeSpaces } from '@/utils/functions';

import { BorderLinkOut } from '@/components/general';
import * as styled from './MainContent.styled';

export const options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <styled.Text>{children}</styled.Text>
    ),
    [BLOCKS.HEADING_2]: (node, children) => (
      <styled.Title>
        <styled.Anchor id={removeSpaces(children.toString())} />
        {children}
      </styled.Title>
    ),
    [BLOCKS.UL_LIST]: (node, children) => (
      <styled.UlList>{children}</styled.UlList>
    ),
    [BLOCKS.OL_LIST]: (node, children) => (
      <styled.OlList>{children}</styled.OlList>
    ),
    [BLOCKS.LIST_ITEM]: (node, children) => (
      <styled.ListItem>{children}</styled.ListItem>
    ),
    [INLINES.HYPERLINK]: (node, children) => (
      <BorderLinkOut href={node.data.uri} rel="nofollow">
        {children}
      </BorderLinkOut>
    ),
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      const { gatsbyImageData, description } = node.data.target;
      return (
        <styled.ImageWrapper>
          <GatsbyImage image={getImage(gatsbyImageData)} alt={description} />
        </styled.ImageWrapper>
      );
    },
    [BLOCKS.EMBEDDED_ENTRY]: (node) => {
      const { listStyleMarker, options } = node.data.target;
      return (
        <styled.CustomList>
          {options.map((option) => (
            <styled.CustomListItem key={option}>
              <styled.CustomListMarker src={listStyleMarker.url} />
              <styled.CustomListItemText>{option}</styled.CustomListItemText>
            </styled.CustomListItem>
          ))}
        </styled.CustomList>
      );
    },
  },
};
