import React from 'react';
import { Link } from 'gatsby';

import { Links } from '@/utils/constants';
import { BlogTags } from '@/components/Blog/BlogArticlesInfo';
import { removeSpaces } from '@/utils/functions';
import { Tag, TagAttachment } from '@/components/Templates/components';
import * as styled from './FeatureArticle.styled';

interface IFeatureArticle {
  $isBlog?: boolean;
  title: string;
  description: string;
  tags: BlogTags[];
  imageUrl: string;
}

export const FeatureArticle: React.FC<IFeatureArticle> = ({
  $isBlog,
  title,
  description,
  tags,
  imageUrl,
}) => (
  <styled.Section $isBlog={$isBlog}>
    {!$isBlog && (
      <styled.TopBar>
        <styled.TopBarTitle>Up Next</styled.TopBarTitle>
        <styled.More to={Links.BLOG}>more articles</styled.More>
      </styled.TopBar>
    )}
    <styled.Box>
      <styled.PictureLink to={`${Links.BLOG}/${removeSpaces(title)}`}>
        <styled.Picture src={imageUrl} $isBlog={$isBlog} />
      </styled.PictureLink>
      <styled.DecriptionBox>
        <styled.TextBox>
          <Link to={`${Links.BLOG}/${removeSpaces(title)}`}>
            <styled.Title $isBlog={$isBlog}>{title}</styled.Title>
          </Link>
          {$isBlog && (
            <styled.TagsBox>
              {tags.map((tag) => (
                <Tag key={tag} attachment={TagAttachment.BLOG}>
                  {tag}
                </Tag>
              ))}
            </styled.TagsBox>
          )}
          <Link to={`${Links.BLOG}/${removeSpaces(title)}`}>
            <styled.Text $isBlog={$isBlog}>{description}</styled.Text>
          </Link>
        </styled.TextBox>
      </styled.DecriptionBox>
    </styled.Box>
  </styled.Section>
);
