import React, { useEffect } from 'react';

import { InputField, Icon, Name } from '@/components/general';

import * as styled from './Modal.styled';

interface IModal {
  formData?: any;
  state: boolean;
  setState: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Modal: React.FC<IModal> = ({ state, setState, formData }) => {
  useEffect(() => {
    if (state) {
      document.body.classList.add('lock');
    } else {
      document.body.classList.remove('lock');
    }
  }, [state]);

  return (
    <styled.Overlay
      $active={state && !formData.data}
      onClick={() => setState(false)}
    >
      <styled.Box
        $active={state && !formData.data}
        onClick={(e) => e.stopPropagation()}
      >
        <styled.CloseBox onClick={() => setState(false)}>
          <Icon name={Name.GENERAL_CLOSE} />
        </styled.CloseBox>
        <styled.Content>
          <styled.Title>Subscribe to new posts</styled.Title>
          <styled.Text>
            Get updates on the newest features, tips and how-tos delivered to
            your inbox.
          </styled.Text>
          <styled.FormBox id="subscribe" onSubmit={formData.handleSubmit}>
            <InputField type="text" name="firstname" required>
              First name
            </InputField>
            <InputField type="text" name="lastname" required>
              Last name
            </InputField>
            <InputField type="email" name="email" required>
              name@example
            </InputField>
            <styled.Button type="submit" $inverted>
              Submit
            </styled.Button>
          </styled.FormBox>
        </styled.Content>
      </styled.Box>
    </styled.Overlay>
  );
};
