import styled from 'styled-components';

import * as text from '@/components/general/TextStyles';
import { StyledButton } from '@/components/general';

export const Button = styled(StyledButton)`
  margin-top: 60px;
  width: 260px;
  height: 50px;

  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    margin-top: 20px;
  }
  @media (max-width: 400px) {
    width: 100%;
    margin-top: 15px;
    @media (max-height: 750px) {
      margin-top: 0px;
    }
  }
`;

interface IActive {
  $active?: boolean;
}

export const Overlay = styled.div<IActive>`
  position: fixed;
  width: 100vw;
  height: ${(props) =>
    `calc(100vh - ${props.theme.headerSize.heightDesktop}px)`};
  top: ${(props) => props.theme.headerSize.heightDesktop}px;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 26px;
  opacity: ${(props: IActive) => (props.$active ? '1' : '0')};
  transition: opacity 0.4s ease;
  pointer-events: ${(props: IActive) => (props.$active ? 'auto' : 'none')};

  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    height: ${(props) =>
      `calc(100vh - ${props.theme.headerSize.heightMobile}px)`};
    top: ${(props) => props.theme.headerSize.heightMobile}px;
  }
`;

export const Box = styled.div<IActive>`
  margin-top: ${(props) => -props.theme.headerSize.heightDesktop / 2}px;
  width: 590px;
  box-shadow: 0px 5px 20px -5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: ${(props) => props.theme.colors.white};
  transform: ${(props: IActive) => `scale(${props.$active ? '1' : '0'})`};
  transition: transform 0.4s ease;
  position: relative;

  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    margin-top: ${(props) => -props.theme.headerSize.heightMobile / 2}px;
    width: 500px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.md}px) {
    width: 90%;
    max-width: 500px;
  }
`;

export const CloseBox = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 50%;
  background-color: transparent;
  transition: background-color 0.4s ease;

  &:hover {
    background-color: #dbdbdb;
  }
`;

export const Close = styled.img``;

export const Content = styled.div`
  padding: 80px 90px 30px;

  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    padding: 50px 60px 30px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.sm}px) {
    padding: 50px 30px 15px;
    @media (max-height: 750px) {
      padding: 30px 30px 15px;
    }
  }
`;

export const Title = styled.h2`
  ${text.FS40}
  margin-bottom: 20px;
  text-align: center;

  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    margin-bottom: 10px;
  }
`;

export const Text = styled.p`
  ${text.FS22}
  color: ${(props) => props.theme.colors.offBlack};
  text-align: center;
  margin-bottom: 50px;

  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    margin-bottom: 20px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.sm}px) {
    margin-bottom: 15px;
  }
`;

export const FormBox = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}px) {
    margin: 0 auto;
    max-width: 250px;
  }
`;
