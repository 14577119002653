import styled from 'styled-components';
import { Link } from 'gatsby';

import * as text from '@/components/general/TextStyles';

export const Box = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  max-width: 250px;
  border-radius: 16px;
  background-color: transparent;
  transition: ${(props) => props.theme.transition.primary};

  &:hover {
    background-color: ${(props) => props.theme.colors.hoverWhite};

    @media (max-width: ${(props) => props.theme.breakpoints.sm}px) {
      background-color: transparent;
    }
  }
`;

export const IconBox = styled.div`
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  margin-bottom: 20px;
  border: 1px solid ${(props) => props.theme.colors.grayText};

  @media (max-width: ${(props) => props.theme.breakpoints.sm}px) {
    margin-bottom: 15px;
  }
`;

export const Title = styled.h3`
  ${text.FS14}
  line-height: 120%;
  font-weight: ${(props) => props.theme.weights.semiBold};
  white-space: nowrap;
`;

export const Description = styled.p`
  ${text.FS14}
  line-height: 120%;
  margin-top: 5.5px;
  color: ${(props) => props.theme.colors.grayText};
  text-align: center;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}px) {
    margin-top: 8.5px;
  }
`;
