import styled from 'styled-components';
import { Link } from 'gatsby';

import * as text from '@/components/general/TextStyles';

interface IIsBlog {
  $isBlog?: boolean;
}

export const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

export const TopBarTitle = styled.h2`
  ${text.FS24toFS20}
`;

export const More = styled(Link)`
  ${text.FS17_NOT_CHANGE}
  color: ${(props) => props.theme.colors.blue}!important;
`;

export const Box = styled.div`
  width: 100%;
  min-height: 350px;
  display: flex;
  align-items: center;
  background-color: ${(props) => props.theme.colors.lightGray2};
  border-radius: 30px;
  padding: 15px 50px 15px 15px;
  overflow: hidden;

  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    flex-direction: column;
    padding: 15px;
  }
`;

export const PictureLink = styled(Link)`
  display: 'flex';
  align-items: 'center';

  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    width: 100%;
  }
`;

export const Picture = styled.img<IIsBlog>`
  border-radius: ${(props: IIsBlog) => (props.$isBlog ? '24px' : '22px')};
  width: ${(props: IIsBlog) => (props.$isBlog ? '430px' : '393px')};
  height: ${(props: IIsBlog) => (props.$isBlog ? 'auto' : '320px')};
  aspect-ratio: 1/0.814;
  max-width: ${(props: IIsBlog) => (props.$isBlog ? '430px' : 'none')};
  max-height: ${(props: IIsBlog) => (props.$isBlog ? '350px' : 'none')};
  object-fit: cover;
  object-position: top;

  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
    max-width: none;
    max-height: ${(props: IIsBlog) => (props.$isBlog ? '334px' : '250px')};
  }
  @media (max-width: ${(props) => props.theme.breakpoints.md}px) {
    width: 100%;
    height: ${(props: IIsBlog) => (props.$isBlog ? 'auto' : '250px')};
  }
  @media (max-width: ${(props) => props.theme.breakpoints.sm}px) {
    height: auto;
  }
`;

export const DecriptionBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 30px;
  padding-left: 40px;

  @media (max-width: ${(props) => props.theme.breakpoints.xl}px) {
    row-gap: 30px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    padding: 20px 0 15px 0;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.sm}px) {
    row-gap: 0px;
  }
`;

export const TextBox = styled.div``;

export const Title = styled.h2<IIsBlog>`
  ${text.FS42}
  line-height: 120%;
  transition: ${(props) => props.theme.transition.primary};

  @media (max-width: ${(props) => props.theme.breakpoints.sm}px) {
    ${(props: IIsBlog) => !props.$isBlog && 'font-size: 20px;'}
  }

  &:hover {
    color: ${(props) => props.theme.colors.blue};
  }
`;

export const Text = styled.p<IIsBlog>`
  ${(props: IIsBlog) => {
    if (props.$isBlog) {
      return text.FS17;
    }
    return text.FS17;
  }}
  ${(props: IIsBlog) => {
    if (!props.$isBlog) {
      return 'line-height: 170%;';
    }
  }}
  margin-top: 20px;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}px) {
    ${(props: IIsBlog) => !props.$isBlog && 'margin-top: 15px;'}
  }
`;

export const TagsBox = styled.div`
  display: flex;
  column-gap: 10px;
  margin-top: 15px;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}px) {
    flex-wrap: wrap;
    row-gap: 10px;
  }
`;

export const Section = styled.section<{ $isBlog?: boolean }>`
  display: flex;
  flex-direction: column;
  z-index: 12;
  max-width: 1105px;

  ${(props) =>
    props.$isBlog &&
    `
      border-bottom: 1px solid ${props.theme.colors.borderColor};
      padding-bottom: 80px;
      margin: 80px auto 0;

      ${Box} {
        background-color: transparent;
        border-radius: 0;
        padding: 0;
      }
      ${DecriptionBox} {
        row-gap: 60px;
        padding: 0 0 0 70px;
      }
    `}

  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    ${(props) =>
      props.$isBlog &&
      `
      margin-top: 40px;
      padding-bottom: 30px;

      ${Box} {
        flex-direction: column-reverse;
      }
      ${DecriptionBox} {
        padding: 0 0 30px 0;
      }
    `}
  }
`;
