import styled from 'styled-components';

import * as text from '@/components/general/TextStyles';

export const Section = styled.section`
  margin-bottom: 100px;

  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    margin-bottom: 60px;
  }
`;

export const MainSection = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: 40px;
  width: 100%;
  max-width: 1366px;
  margin: 0 auto;
  padding: 0 60px;

  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    padding: 0 28px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.sm}px) {
    padding: 0 20px;
  }
`;

export const SocialsBox = styled.div`
  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    display: none;
  }
`;

export const SocialsStickyBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 16px;
  position: sticky;
  top: ${(props) => `calc(${props.theme.headerSize.heightDesktop}px + 15px)`};
`;

export const GrayTitle = styled.h4`
  ${text.FS14}
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.grayText};
`;

export const SocialsLink = styled.a`
  width: 30px;
  height: 30px;
  border-radius: 50px;
  overflow: hidden;
`;

export const SocialsImage = styled.img`
  width: inherit;
  height: inherit;
`;

export const MainBox = styled.div`
  max-width: 750px;

  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    max-width: none;
  }
`;

export const MainArticles = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 28px;
  margin-bottom: 100px;

  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    margin-bottom: 60px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.sm}px) {
    row-gap: 25px;
  }
`;

export const TitleH2 = styled.h2`
  ${text.FS32}
`;

export const FeaturesList = styled.ul``;

export const FeatureTitle = styled.h2`
  ${text.FS32}
  margin-bottom: 20px;
  display: none;

  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    display: block;
    margin-bottom: 16px;
  }
`;

interface IProfileBox {
  $maxHeight: number;
}

export const ProfileBox = styled.div<IProfileBox>`
  width: 260px;
  min-width: 240px;
  max-height: ${(props: IProfileBox) => `${props.$maxHeight}px`};

  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    display: none;
  }
`;

export const ProfileStickyBox = styled.div`
  position: sticky;
  top: ${(props) => `calc(${props.theme.headerSize.heightDesktop}px + 20px)`};
`;

export const ProfileHead = styled.div`
  display: flex;
  padding-bottom: 25.5px;
  border-bottom: 1px solid ${(props) => props.theme.colors.borderGray};
`;

export const ProfilePhoto = styled.img`
  width: 70px;
  height: 70px;
  border-radius: 50%;
`;

export const ProfileDescription = styled.div`
  margin-left: 14px;
`;

export const Name = styled.span`
  ${text.FS14}
  line-height: 150%;
  font-weight: ${(props) => props.theme.weights.bold};
  margin-bottom: 3px;
`;

export const JobTitle = styled.p`
  ${text.FS14}
  line-height: 150%;
  margin-bottom: 5px;
`;

export const AuthorLinksBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 15px;
  row-gap: 10px;
`;

export const AuthorLink = styled.a`
  ${text.FS14}
  color: ${(props) => props.theme.colors.blue}!important;
`;

export const ContentsBox = styled.div`
  padding: 25px 0;
  border-bottom: 1px solid ${(props) => props.theme.colors.borderGray};
`;

export const ContentsList = styled.ul`
  margin-top: 10px;
  margin-left: -10px;
  display: flex;
  flex-direction: column;
  scroll-behavior: smooth;
  row-gap: 3px;
`;

interface IListItem {
  $active: boolean;
}

export const ListItem = styled.li<IListItem>`
  padding: 3px 10px;
  border-radius: 8px;
  ${text.FS14}
  line-height: 170%;
  background-color: ${(props) =>
    props.$active ? props.theme.colors.lightGray : 'transparent'};
  cursor: pointer;

  &:hover {
    background: ${(props) => props.theme.colors.lightGray2};
  }
`;

export const ProfileBottom = styled.div`
  margin-top: 30px;

  & > button {
    width: 100%;
    height: 36px;
  }
`;

export const ProfileCapture = styled.p`
  margin-top: 10px;
  font-weight: ${(props) => props.theme.weights.regular};
  font-size: 12px;
  line-height: 130%;
  text-align: center;
  letter-spacing: -0.004em;
  color: ${(props) => props.theme.colors.grayText};

  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    max-width: 260px;
  }
`;

export const NextSsection = styled.section`
  margin-top: 120px;
  width: 100%;

  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    margin-top: 60px;
  }
`;

export const NextFlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.blue};
  border-radius: ${(props) => props.theme.borderRadius.md}px;
  color: ${(props) => props.theme.colors.white};
  padding-bottom: calc(${(props) => props.theme.spacing.xxl1}px / 2);
  padding-top: calc(${(props) => props.theme.spacing.xxl1}px / 2);

  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    padding: calc(${(props) => props.theme.spacing.xl2}px / 2) 30px;
  }
`;

export const NextButtonBox = styled.div`
  margin-top: 55px;
  display: flex;
  justify-content: space-between;

  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    margin-top: 35px;
  }
`;

interface IAdaptiveContentsBox {
  open?: boolean;
  $adaptiveHeight?: number;
}

export const AdaptiveContentsBox = styled.div<IAdaptiveContentsBox>`
  display: none;

  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    display: block;
    background: ${(props) => props.theme.colors.lightGray2};
    border-radius: 16px;
    margin-bottom: 20px;
    padding: 15px;
    max-width: 450px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.sm}px) {
    max-width: none;
  }
`;

export const AdaptiveContentsTitleBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const AdaptiveContentsTitle = styled.h4`
  ${text.FS14}
  line-height: 150%;
  font-weight: ${(props) => props.theme.weights.bold};
  text-transform: uppercase;
`;

export const AdaptiveContentsHideBox = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const AdaptiveContentsText = styled.span`
  ${text.FS14}
  line-height: 17px;
  color: ${(props) => props.theme.colors.grayText};
  margin-right: 8px;
`;

export const AdaptiveContentsArrow = styled.div<{ open: boolean }>`
  transition: ${(props) => props.theme.transition.primary};
  transform: ${(props) => `rotate(${props.open ? '180deg' : '0deg'})`};
`;

export const AdaptiveContentsListItemsBox = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`;

export const AdaptiveContentsList = styled.ul<IAdaptiveContentsBox>`
  ${text.FS14}
  line-height: 170%;
  padding-top: ${(props: IAdaptiveContentsBox) => (props.open ? '20px' : '0')};
  opacity: ${(props: IAdaptiveContentsBox) => (props.open ? '1' : '0')};
  pointer-events: ${(props: IAdaptiveContentsBox) =>
    props.open ? 'auto' : 'none'};
  height: ${(props: IAdaptiveContentsBox) =>
    props.open ? `${props.$adaptiveHeight}px` : '0'};
  transition: 0.2s opacity ease, 0.4s height ease, 0.4s padding-top ease;
`;

export const AdaptiveListItem = styled.li`
  cursor: pointer;
`;
