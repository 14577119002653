import React, { useState, useRef, useEffect, useContext } from 'react';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { useForm } from 'react-hubspot';
import { ThemeContext } from 'styled-components';

import facebook from '@/images/Blog/socials-facebook.svg';
import linkedin from '@/images/Blog/socials-linkedin.svg';
import twitter from '@/images/Blog/socials-twitter.svg';

import {
  Layout,
  BlueBox,
  Feature,
  Breadcrumbs,
  Button,
  ButtonColors,
  Icon,
  Name,
} from '@/components/general';
import { Links } from '@/utils/constants';
import * as styled from './Content.styled';
import {
  FeatureArticle,
  WhatSection,
  Header,
  Modal,
  options,
} from './components';

interface IContentsElement {
  text: string;
  index: number;
  element: HTMLHeadingElement;
}

interface IContent {
  data: any;
}

const Content: React.FC<IContent> = ({ data }) => {
  const theme = useContext(ThemeContext);

  const articleData = data.contentfulArticle;
  const allArticles = data.contentfulBlogPage.articles;
  const currentArticle = allArticles.find(
    (article) => article.id === articleData.id,
  );
  const indexOfCurrentArticle = allArticles.indexOf(currentArticle);
  let upNextArticle = null;
  if (articleData.upNextArticle) {
    upNextArticle = articleData.upNextArticle;
  } else if (allArticles[indexOfCurrentArticle + 1]) {
    upNextArticle = allArticles[indexOfCurrentArticle + 1];
  } else if (allArticles[indexOfCurrentArticle - 1]) {
    upNextArticle = allArticles[indexOfCurrentArticle - 1];
  } else upNextArticle = allArticles[indexOfCurrentArticle];

  const articleContent = renderRichText(articleData.content, options);

  const [open, setOpen] = useState(false);

  const contentsListRef = useRef(null);

  const MainArticlesRef = useRef(null);

  const [mainBoxHeight, setMainBoxHeight] = useState<number>(0);

  const MainBoxRef = useRef(null);

  useEffect(() => {
    if (MainBoxRef && MainBoxRef.current) {
      setMainBoxHeight(MainBoxRef.current.offsetHeight);
    }
  }, [MainBoxRef?.current?.offsetHeight]);

  let menuItemsGlobal: IContentsElement[] = [];
  const [menuItems, setMenuItems] = useState<IContentsElement[]>([]);

  let contentsIndex = 0;
  useEffect(() => {
    MainArticlesRef.current.childNodes.forEach((node) => {
      if (
        node.className ===
        'gatsby-image-wrapper gatsby-image-wrapper-constrained'
      ) {
        if (node.nextSibling.tagName === 'H2') {
          /* eslint-disable no-param-reassign */
          node.style.marginBottom = '0px';
          /* eslint-enable */
        }
      }
      if (node.tagName === 'H2') {
        menuItemsGlobal = [
          ...menuItemsGlobal,
          {
            text: node.innerText,
            index: contentsIndex,
            element: node,
          },
        ];
        setMenuItems(menuItemsGlobal);
        contentsIndex++;
      }
    });
  }, [MainArticlesRef]);

  const tableOfContents = articleContent.filter(
    (el) => el.type.target === 'h2',
  );

  const [menuItemActive, setMenuItemActive] = useState<
    IContentsElement | undefined
  >(undefined);

  useEffect(() => {
    setMenuItemActive(menuItemsGlobal[0]);
  }, []);

  useEffect(() => {
    if (window.innerWidth >= 767) {
      const setActive = () => {
        if (menuItemActive) {
          const currentIndex = menuItemActive.index;
          const nextIndex = currentIndex + 1;
          const previousIndex = currentIndex - 1;

          if (menuItems[nextIndex]) {
            if (menuItems[nextIndex].element.offsetTop <= scrollY) {
              setMenuItemActive(menuItems[nextIndex]);
            }
          }
          if (menuItems[previousIndex]) {
            if (menuItems[currentIndex].element.offsetTop > scrollY) {
              setMenuItemActive(menuItems[previousIndex]);
            }
          }
        }
      };
      setActive();
      window.addEventListener('scroll', setActive);
      return () => window.removeEventListener('scroll', setActive);
    }
  }, [menuItemActive]);

  const formData = useForm({
    portalId: process.env.GATSBY_HUBSPOT_PORTAL_ID,
    formId: process.env.GATSBY_HUBSPOT_NEWSLETTER_FORM_ID,
  });

  const [subscribe, setSubscribe] = useState<boolean>(false);

  const onContentsClick = (index: number) => {
    if (menuItems.length !== 0) {
      window.scrollTo({
        top: menuItems[index].element.offsetTop,
        behavior: 'smooth',
      });
      setMenuItemActive(menuItems[index]);
    }
  };

  return (
    <Layout>
      <Breadcrumbs $hideOnLg links={[Links.BLOG, articleData.title]} />
      <styled.Section itemScope itemType="https://schema.org/Article">
        <Header
          title={articleData.title}
          date={articleData.date}
          tags={articleData.tags}
          description={articleData.description.description}
          image={articleData.primaryImage}
          author={articleData.author}
        />
        <styled.MainSection>
          <styled.SocialsBox>
            <styled.SocialsStickyBox>
              <styled.GrayTitle>share</styled.GrayTitle>
              <styled.SocialsLink href="https://www.facebook.com/">
                <styled.SocialsImage src={facebook} />
              </styled.SocialsLink>
              <styled.SocialsLink href="https://linkedin.com">
                <styled.SocialsImage src={linkedin} />
              </styled.SocialsLink>
              <styled.SocialsLink href="https://twitter.com">
                <styled.SocialsImage src={twitter} />
              </styled.SocialsLink>
            </styled.SocialsStickyBox>
          </styled.SocialsBox>
          <styled.MainBox ref={MainBoxRef}>
            <styled.AdaptiveContentsBox open={open}>
              <styled.AdaptiveContentsTitleBox>
                <styled.AdaptiveContentsTitle>
                  table of contents
                </styled.AdaptiveContentsTitle>
                <styled.AdaptiveContentsHideBox onClick={() => setOpen(!open)}>
                  <styled.AdaptiveContentsText>
                    {open ? 'Hide' : 'Expand'}
                  </styled.AdaptiveContentsText>
                  <styled.AdaptiveContentsArrow open={open}>
                    <Icon
                      name={Name.GENERAL_FEATURE_ARROW}
                      color={theme.colors.grayText}
                      width={12}
                      height={12}
                    />
                  </styled.AdaptiveContentsArrow>
                </styled.AdaptiveContentsHideBox>
              </styled.AdaptiveContentsTitleBox>
              <styled.AdaptiveContentsList
                open={open}
                $adaptiveHeight={
                  contentsListRef?.current?.getBoundingClientRect()?.height + 15
                }
              >
                <styled.AdaptiveContentsListItemsBox ref={contentsListRef}>
                  {tableOfContents.map((item, index) => {
                    const listitem = item.props.children[1][0];
                    return (
                      <styled.AdaptiveListItem
                        key={index}
                        onClick={() => onContentsClick(index)}
                      >
                        {index + 1}. {listitem}
                      </styled.AdaptiveListItem>
                    );
                  })}
                </styled.AdaptiveContentsListItemsBox>
              </styled.AdaptiveContentsList>
            </styled.AdaptiveContentsBox>
            <styled.MainArticles ref={MainArticlesRef} itemProp="articleBody">
              {articleContent}
            </styled.MainArticles>
            {articleData?.faq && (
              <styled.FeaturesList>
                <styled.FeatureTitle>Features</styled.FeatureTitle>
                {articleData.faq.map((item) => (
                  <Feature
                    key={item.id}
                    question={item.question}
                    answer={item.answer.answer}
                  />
                ))}
              </styled.FeaturesList>
            )}
          </styled.MainBox>
          <styled.ProfileBox $maxHeight={mainBoxHeight}>
            <styled.ProfileStickyBox>
              <styled.ProfileHead
                itemProp="author"
                itemScope
                itemType="https://schema.org/person"
              >
                <styled.ProfilePhoto
                  src={articleData?.author?.photo?.url}
                  itemProp="image"
                />
                <styled.ProfileDescription>
                  <styled.Name itemProp="name">
                    {articleData.author.name}
                  </styled.Name>
                  <styled.JobTitle itemProp="jobTitle">
                    {articleData?.author?.jobTitle}
                  </styled.JobTitle>
                  <styled.AuthorLinksBox>
                    {articleData?.author?.authorLinks?.map((url) => (
                      <styled.AuthorLink
                        key={url.link}
                        href={url.link}
                        itemProp="url"
                      >
                        {url.text}
                      </styled.AuthorLink>
                    ))}
                  </styled.AuthorLinksBox>
                </styled.ProfileDescription>
              </styled.ProfileHead>
              <styled.ContentsBox>
                <styled.GrayTitle>table of contents:</styled.GrayTitle>
                <styled.ContentsList>
                  {tableOfContents.map((item, index) => {
                    const listitem = item.props.children[1][0];
                    return (
                      <styled.ListItem
                        key={index}
                        onClick={() => onContentsClick(index)}
                        $active={menuItemActive?.text === listitem}
                      >
                        {index + 1}. {listitem}
                      </styled.ListItem>
                    );
                  })}
                </styled.ContentsList>
              </styled.ContentsBox>
              <styled.ProfileBottom>
                <Button
                  color={ButtonColors.SECONDARY}
                  onClick={() => setSubscribe(true)}
                >
                  Subscribe to new posts
                </Button>
                <Modal
                  formData={formData}
                  state={subscribe}
                  setState={setSubscribe}
                />
                <styled.ProfileCapture>
                  Get weekly updates on the newest design stories, case studies
                  and tips right in your mailbox
                </styled.ProfileCapture>
              </styled.ProfileBottom>
            </styled.ProfileStickyBox>
          </styled.ProfileBox>
        </styled.MainSection>
      </styled.Section>
      <FeatureArticle
        title={upNextArticle.title}
        description={upNextArticle.description.description}
        tags={upNextArticle.tags}
        imageUrl={upNextArticle.primaryImage.url}
      />
      <WhatSection />
      <BlueBox
        title="What's next?"
        description="Start building great apps today"
      />
    </Layout>
  );
};

export default Content;
