import styled from 'styled-components';

import * as text from '@/components/general/TextStyles';

export const WhatSection = styled.section`
  margin-top: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    margin-top: 100px;
  }
`;

export const WhatGrid = styled.div`
  margin: 73px -20px 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 46px;
  row-gap: 20px;

  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    row-gap: 40px;
    margin-top: 30px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.sm}px) {
    row-gap: 20px;
  }
`;

export const WhatTitle = styled.h2`
  ${text.FS42}
`;
